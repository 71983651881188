import { Edit, NumberInput, required, SimpleForm, TextInput } from 'react-admin';

const OnboardingEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled source="id" validate={required()} />
        <NumberInput min={0} source="index" validate={required()} />
        <NumberInput isRequired min={0} source="progress" validate={required()} />
        <TextInput isRequired source="name" validate={required()} />
        <TextInput isRequired source="title" validate={required()} />
        <TextInput isRequired multiline source="text" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default OnboardingEdit;
