import { Avatar, Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Icons, IconType } from '../types/Icons';
import UserProfile from '../types/User';

const SocialsField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext();
  const social: UserProfile['links'] = record[source!] ?? [];
  const size = 16;

  return (
    <Stack direction="column" spacing={1}>
      {social.map(({ icon, label, link }, idx) => {
        return (
          <Stack key={idx} direction="row">
            <Chip avatar={<Avatar variant="rounded" sx={{ maxWidth: size, maxHeight: size, width: size, height: size }} src={Icons[icon as IconType]} />} label={label} sx={{ width: '20%', minWidth: 128, borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
            <Typography variant="body1" sx={{ paddingLeft: '8px', border: '1px solid #ccc', flex: 1, borderTopRightRadius: 100, borderBottomRightRadius: 100 }}>{link}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default SocialsField;
