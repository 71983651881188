import { Add, Cancel as IconCancel } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
import {
  Button,
  Datagrid,
  DeleteWithConfirmButton,
  email,
  ExportButton,
  Form,
  List,
  minLength,
  ReferenceField,
  required,
  SaveButton,
  TextField,
  TextInput,
  TopToolbar,
  useNotify,
  useRefresh,
} from "react-admin";
import { sleep } from "../utils/sleep";

const createAdmin = async (email: string, password: string) => {
  interface Request {
    email: string;
    password: string;
  }
  interface Response {
    uid: string;
    error?: string;
  }

  const { data } = await httpsCallable<Request, Response>(
    getFunctions(),
    "createAdmin"
  )({ email, password });
  return data;
};

const AddAdminButton = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);
  const handleFormSubmit = async (data: Record<string, string>) => {
    const email = data["email"];
    const password = data["password"];

    try {
      const { error } = await createAdmin(email, password);

      if (error) {
        notify(error, {
          type: "error",
        });
        return;
      }

      await sleep(2500);
      refresh();
      setShowDialog(false);
    } catch (e: any) {
      console.error(e);
      notify("An unexpected error occurred", {
        type: "error",
      });
    }
  };

  return (
    <>
      <Button label="Add Admin" startIcon={<Add />} onClick={handleClick} />
      <Dialog open={showDialog} fullWidth onClose={handleCloseClick}>
        <DialogTitle>Add New Admin</DialogTitle>
        <Form onSubmit={handleFormSubmit}>
          <DialogContent>
            <TextInput
              fullWidth
              source="email"
              type="email"
              isRequired
              placeholder="somebody@example.com"
              validate={[required(), email()]}
            />
            <br />
            <TextInput
              fullWidth
              source="password"
              type="password"
              isRequired
              validate={[required(), minLength(8)]}
            />
            <TextInput
              fullWidth
              source="confirmPassword"
              type="password"
              isRequired
              validate={[required(), minLength(8), confirmPassword]}
            />
            <br />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const confirmPassword = (value: any, values: any) => {
  const password = values.password;
  if (value && value !== password) {
    return "Passwords confirmation must match password";
  }
  return undefined;
};

const AdminListActions = () => {
  return (
    <TopToolbar>
      <AddAdminButton />
      <ExportButton />
    </TopToolbar>
  );
};

const AdminList = () => {
  return (
    <List actions={<AdminListActions />}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" />
        <ReferenceField label="Email" reference="User" source="id">
          <TextField label="Email" source="email" />
        </ReferenceField>
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

export default AdminList;
