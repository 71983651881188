import { Chip, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FieldProps, useGetList, useRecordContext } from "react-admin";
import UserProfile from "../types/User";

const OnboardingField: FC<FieldProps> = ({ source }) => {
  const record = useRecordContext<UserProfile>();
  const steps = useGetList("Onboarding", {
    sort: {
      field: "index",
      order: "ASC",
    },
  });
  const onboarding = (record[source as keyof UserProfile] ?? []) as Record<
    string,
    boolean | undefined
  >;

  return (
    <Stack>
      <Stack direction="column" spacing={1}>
        {steps.data?.map(({ name }) => (
          <Stack key={name} direction="row">
            <Chip
              label={name}
              sx={{
                width: "20%",
                minWidth: 128,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                paddingLeft: "8px",
                border: "1px solid #ccc",
                flex: 1,
                borderTopRightRadius: 100,
                borderBottomRightRadius: 100,
              }}
            >
              {onboarding[name] ? "true" : "false"}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default OnboardingField;
