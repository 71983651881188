import DefaultIcon from "@mui/icons-material/ViewList";
import React from "react";
import {
  DashboardMenuItem,
  Menu as BaseMenu,
  MenuItemLink,
  MenuProps,
  useResourceDefinitions,
  useSidebarState,
} from "react-admin";

type Props = MenuProps & {
  onMenuClick?: () => void;
};

const Menu: React.FC<Props> = ({ onMenuClick, ...props }) => {
  const resources = useResourceDefinitions();
  const [open] = useSidebarState();
  return (
    <BaseMenu {...props}>
      <DashboardMenuItem />
      {Object.keys(resources).map((name) => {
        const Icon = resources[name]?.icon;
        return (
          <MenuItemLink
            key={name}
            to={`/${name}`}
            primaryText={
              (resources[name].options && resources[name].options.label) || name
            }
            leftIcon={Icon ? <Icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
      {/* <Logout /> */}
    </BaseMenu>
  );
};

export default Menu;
