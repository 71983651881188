import { FieldProps, useRecordContext } from 'react-admin';

const TitleField = ({ label, source }: FieldProps) => {
  const record = useRecordContext();
  if (!record) {
    return label || "";
  }

  const value = record[source!];

  if (label && value) {
    return (
      <span>{label} "{value}"</span>
    );
  }

  return value || label || "";
};

export default TitleField;
