import { Datagrid, ImageField, List, TextField } from "react-admin";

export const ChannelList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField label="Title" source="name" />
      <ImageField title="Image" label="Image" source="image.src" />
    </Datagrid>
  </List>
);
