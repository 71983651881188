import { Edit, ImageField, ImageInput, required, SimpleForm, TextInput } from "react-admin";

const ChannelEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput label="Title" isRequired source="name" validate={required()} />
        <TextInput source="subtitle" />
        <ImageInput source="image">
          <ImageField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default ChannelEdit;
