export const Icons = {
  activity: require('../assets/icons/icon-activity.png'),
  appearance: require('../assets/icons/icon-appearance.png'),
  avatar: require('../assets/icons/icon-avatar.png'),
  calendar: require('../assets/icons/icon-calendar.png'),
  'calendar-custom': require('../assets/icons/icon-calendar-custom.png'),
  'calendar-plus-1': require('../assets/icons/icon-calendar-plus-1.png'),
  'calendar-plus-30': require('../assets/icons/icon-calendar-plus-30.png'),
  'calendar-plus-7': require('../assets/icons/icon-calendar-plus-7.png'),
  calendly: require('../assets/icons/icon-calendly.png'),
  'camera-permission': require('../assets/icons/icon-camera-permission.png'),
  cashapp: require('../assets/icons/icon-cashapp.png'),
  check: require('../assets/icons/icon-check.png'),
  'chevron-left': require('../assets/icons/icon-chevron-left.png'),
  'chevron-right': require('../assets/icons/icon-chevron-right.png'),
  channels: require('../assets/icons/icon-channels.png'),
  close: require('../assets/icons/icon-close.png'),
  'contact-new': require('../assets/icons/icon-contact-new.png'),
  'complete-check': require('../assets/icons/icon-complete-check.png'),
  dial: require('../assets/icons/icon-dial.png'),
  discord: require('../assets/icons/icon-discord.png'),
  download: require('../assets/icons/icon-download.png'),
  'dots-vertical': require('../assets/icons/icon-dots-h.png'),
  'dots-horizontal': require('../assets/icons/icon-dots-v.png'),
  dribbble: require('../assets/icons/icon-dribbble.png'),
  email: require('../assets/icons/icon-email.png'),
  erase: require('../assets/icons/icon-erase.png'),
  etsy: require('../assets/icons/icon-etsy.png'),
  event: require('../assets/icons/icon-event.png'),
  facebook: require('../assets/icons/icon-facebook.png'),
  'followup-check': require('../assets/icons/icon-followup-check.png'),
  'followup-new': require('../assets/icons/icon-followup-new.png'),
  github: require('../assets/icons/icon-github.png'),
  'group-new': require('../assets/icons/icon-group-new.png'),
  groups: require('../assets/icons/icon-groups.png'),
  instagram: require('../assets/icons/icon-instagram.png'),
  keypad: require('../assets/icons/icon-keypad.png'),
  link: require('../assets/icons/icon-link.png'),
  linkedin: require('../assets/icons/icon-linkedin.png'),
  location: require('../assets/icons/icon-location.png'),
  logout: require('../assets/icons/icon-logout.png'),
  messenger: require('../assets/icons/icon-messenger.png'),
  minus: require('../assets/icons/icon-minus.png'),
  note: require('../assets/icons/icon-note.png'),
  notifications: require('../assets/icons/icon-notifications.png'),
  paypal: require('../assets/icons/icon-paypal.png'),
  phone: require('../assets/icons/icon-phone.png'),
  pinterest: require('../assets/icons/icon-pinterest.png'),
  place: require('../assets/icons/icon-place.png'),
  places: require('../assets/icons/icon-places.png'),
  plus: require('../assets/icons/icon-plus.png'),
  preferences: require('../assets/icons/icon-preferences.png'),
  qr: require('../assets/icons/icon-qr.png'),
  rating: require('../assets/icons/icon-rating.png'),
  search: require('../assets/icons/icon-search.png'),
  security: require('../assets/icons/icon-security.png'),
  settings: require('../assets/icons/icon-settings.png'),
  slack: require('../assets/icons/icon-slack.png'),
  snapchat: require('../assets/icons/icon-snapchat.png'),
  spotify: require('../assets/icons/icon-spotify.png'),
  tags: require('../assets/icons/icon-tags.png'),
  telegram: require('../assets/icons/icon-telegram.png'),
  text: require('../assets/icons/icon-text.png'),
  trash: require('../assets/icons/icon-trash-can.png'),
  tiktok: require('../assets/icons/icon-tiktok.png'),
  twitch: require('../assets/icons/icon-twitch.png'),
  twitter: require('../assets/icons/icon-twitter.png'),
  up: require('../assets/icons/icon-up.png'),
  writing: require('../assets/icons/icon-writing.png'),
  venmo: require('../assets/icons/icon-venmo.png'),
  vimeo: require('../assets/icons/icon-vimeo.png'),
  vsco: require('../assets/icons/icon-vsco.png'),
  wallet: require('../assets/icons/icon-wallet.png'),
  whatsapp: require('../assets/icons/icon-whatsapp.png'),
  youtube: require('../assets/icons/icon-youtube.png'),
  caret: require('../assets/icons/icon-caret.png'),
  'tags-placeholder': require('../assets/icons/icon-tags-placeholder.png'),
  'groups-placeholder': require('../assets/icons/icon-groups-placeholder.png'),
  'places-placeholder': require('../assets/icons/icon-places-placeholder.png'),
  'contacts-placeholder': require('../assets/icons/icon-contacts-placeholder.png'),
  'activity-placeholder': require('../assets/icons/icon-activity-placeholder.png'),
  web: require('../assets/icons/icon-web.png'),
} as const;

export type IconType = keyof typeof Icons;

export function isIconType(obj: any): obj is IconType {
  return typeof obj === 'string' && obj in Icons;
}
