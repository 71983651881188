import { sleep } from "./sleep";

type Callback<T = void> = () => Promise<T>;

interface Options {
  maxRetries?: number;
  delayFunc?: (retry: number) => number;
}

const defaultMaxRetries = 5;
const defaultDelayFunc = (retry: number) => Math.pow(2, retry-1) * 300;

export async function backoff<T>(callback: Callback<T>, options?: Options): Promise<T> {
  let retries = 0;
  const maxRetries = Math.max(1, options?.maxRetries ?? defaultMaxRetries);
  const delayFunc = options?.delayFunc ?? defaultDelayFunc;
  while (true) {
    try {
      return await callback();
    } catch (e: any) {
      retries += 1;
      if (retries > maxRetries) {
        console.error('Previous error', e);
        throw new Error(`Backoff max retries exceeded: ${retries} > ${maxRetries}`);
      }

      const delay = delayFunc(retries);
      await sleep(delay);
    }
  }
}
