import { Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin';

const OnboardingCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <NumberInput min={0} source="index" validate={required()} />
        <NumberInput min={0} source="progress" validate={required()} />
        <TextInput isRequired source="name" validate={required()} />
        <TextInput isRequired source="title" validate={required()} />
        <TextInput isRequired multiline source="text" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default OnboardingCreate;
