import { BioText } from "../types/BioText";
import { at, ensureArray } from "./array";

export const urlRegex =
  /\b(?:https?:\/\/[-a-z0-9@:%._+~#=]{1,256}\.[a-z0-9()]{1,6}|[-a-z0-9@:%._+~#=]{1,256}\.(?:com|net|org|jp|de|uk|fr|br|it|ru|es|me|gov|pl|ca|au|cn|co|in|nl|edu|info|eu|ch|id|at|kr|cz|mx|be|tv|se|tr|tw|al|ua|ir|vn|cl|sk|ly|cc|to|no|fi|us|pt|dk|ar|hu|tk|gr|il|news|ro|my|biz|ie|za|nz|sg|ee|th|io|xyz|pe|bg|hk|rs|lt|link|ph|club|si|site|mobi|by|cat|wiki|la|ga|xxx|cf|hr|ng|jobs|online|kz|ug|gq|ae|is|lv|pro|fm|tips|ms|sa|app))([-a-z0-9()@:%_+.~#?&/=]*)/gi;

const httpsCheck = (link: string) => {
  if (link.includes("https")) {
    return link;
  }

  return `https://${link}`;
};

function normalizeUrl(url: string): string {
  if (!url.match(/^[a-z]+:\/\//gi)) {
    return httpsCheck(url);
  }

  return url;
}

export function parseBioLinks(bio: string): BioText {
  const urls = bio.match(urlRegex);
  if (!urls) {
    return bio;
  }

  type Range = {
    start: number;
    end: number;
    text: string;
    link: boolean;
  };

  const linkRanges: Array<Range> = [];
  urls.forEach((url) => {
    const start = bio.indexOf(url, at(linkRanges, -1)?.end);
    const end = start + url.length;

    linkRanges.push({
      start,
      end,
      text: url,
      link: true,
    });
  });

  const ranges: Array<Range> = [];

  while (linkRanges.length) {
    const previousRange = at(ranges, -1);
    const range = linkRanges.shift()!;
    if (!previousRange || range.start !== previousRange.end) {
      const previousStart = previousRange?.end ?? 0;

      ranges.push({
        start: previousStart,
        end: range.start,
        text: bio.slice(previousStart, range.start),
        link: false,
      });
    }

    ranges.push(range);
  }

  const last = at(ranges, -1);
  if (!last || last.end !== bio.length - 1) {
    const start = last?.end ?? 0;
    const end = bio.length;
    ranges.push({
      start,
      end,
      text: bio.slice(start, end),
      link: false,
    });
  }

  const results: BioText = ranges.map(({ start, end, link }) => {
    const text = bio.slice(start, end);

    if (!link) {
      return {
        text,
      };
    }

    return {
      text,
      url: normalizeUrl(text),
    };
  });

  return results;
}

export function flattenBioText(bio: BioText): string {
  return ensureArray(bio).map(block => {
    if (typeof block === 'string') {
      return block;
    }

    return block.text;
  }).join('');
}
