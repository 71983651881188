import { Stack } from "@mui/material";
import { Button, useRecordContext, useUpdate } from "react-admin";
import UserProfile from "../types/User";

export const ResetOnboardingField = () => {
  const record = useRecordContext<UserProfile>();

  const [reset] = useUpdate("User", {
    id: record.id,
    data: {
      onboarding: {},
    },
    previousData: record,
  });

  const handleResetOnboarding = () => reset();

  return (
    <Stack direction="row" justifyContent="flex-end" marginTop={1}>
      <Button
        variant="outlined"
        label="Reset Onboarding"
        onClick={handleResetOnboarding}
      />
    </Stack>
  );
};
