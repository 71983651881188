import { Chip, Stack } from '@mui/material';
import { FieldProps, useRecordContext } from 'react-admin';

type Props = {
  max?: number;
};

const TagsField = ({ max, source }: FieldProps & Props) => {
  const record = useRecordContext();
  const tags: ReadonlyArray<string> = record[source!] ?? [];
  return (
    <Stack direction="row" spacing={2}>
      {tags.slice(0, max).map((tag, index) => (
        <Chip key={index} label={tag} />
      ))}
      {max !== undefined && tags.length > max && (
        <Chip variant="outlined" label={`+ ${tags.length - max}`} />
      )}
    </Stack>
  );
};

export default TagsField;
