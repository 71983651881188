import { Add, Cancel as IconCancel } from "@mui/icons-material";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
import {
  Button,
  Datagrid,
  DateField,
  EmailField,
  ExportButton,
  FilterButton,
  Form,
  FunctionField,
  List,
  required,
  SaveButton,
  SearchInput,
  TextField,
  TextInput,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import TagsField from "../fields/TagsField";
import UserProfile from "../types/User";
import { backoff } from "../utils/backoff";
import { sleep } from "../utils/sleep";
import { formatText } from "../utils/text";
import formatName from "../utils/formatName";

const createUser = async (phone: string) => {
  interface Request {
    phone: string;
  }
  interface Response {
    uid: string;
    error?: string;
  }

  const { data } = await httpsCallable<Request, Response>(
    getFunctions(),
    "createUser",
  )({ phone });
  return data;
};

const AddUserButton = () => {
  const redirect = useRedirect();
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => setShowDialog(true);
  const handleCloseClick = () => setShowDialog(false);
  const handleFormSubmit = async (data: Record<string, string>) => {
    const phone = data["phone"];
    try {
      const { uid, error } = await createUser(phone);

      if (error) {
        notify(error, {
          type: "error",
        });
        return;
      }

      await sleep(2500);
      let count = 1;
      await backoff(
        () => {
          console.log(`try!!2234 ${count++}`);
          return dataProvider.getOne("PulseProfiles", { id: uid });
        },
        {
          delayFunc: (retry) => Math.pow(2, retry - 1) * 500,
        },
      );

      refresh();
      redirect(`/User/${uid}/show`);
    } catch (e: any) {
      console.error(e);
      notify("An unexpected error occurred", {
        type: "error",
      });
    }
  };

  return (
    <>
      <Button label="Add User" startIcon={<Add />} onClick={handleClick} />
      <Dialog open={showDialog} fullWidth onClose={handleCloseClick}>
        <DialogTitle>Add New User</DialogTitle>
        <Form onSubmit={handleFormSubmit}>
          <DialogContent>
            <TextInput
              source="phone"
              type="phone"
              isRequired
              placeholder="+15555555555"
              validate={required()}
            />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick}>
              <IconCancel />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const UserListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <AddUserButton />
      <ExportButton />
    </TopToolbar>
  );
};

const UserList = () => {
  const emptyTextStyle = { fontStyle: "italic", color: "silver" };

  const filters = [
    <TextInput label="Search by first name" source="name.first" alwaysOn />,
    <TextInput label="Search by last name" source="name.last" alwaysOn />,
    <TextInput label="Id" source="id" />,
    <TextInput label="Username" source="username" />,
    <TextInput label="Email" source="email" />,
    <TextInput label="Tags" source="tags" />,
  ];

  return (
    <List filters={filters} actions={<UserListActions />}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <FunctionField
          source="username"
          render={(record: UserProfile) => (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                src={record.photoUrl}
                variant="circular"
                sx={{ width: 24, height: 24 }}
              />
              <Typography
                sx={!record.username?.trim() ? emptyTextStyle : undefined}
              >
                {formatText(record.username)}
              </Typography>
            </Stack>
          )}
        />
        <FunctionField
          source="name"
          render={(record: UserProfile) => (
            <Typography
              sx={{
                whiteSpace: "nowrap",
                ...(!formatName(record.name) ? emptyTextStyle : undefined),
              }}
            >
              {formatName(record.name)}
            </Typography>
          )}
        />
        <TextField source="phone.primary.number" />
        <EmailField source="email.primary.address" />
        <TagsField source="tags" sortable={false} max={5} />
        <DateField showTime source="dateAdded" />
      </Datagrid>
    </List>
  );
};

export default UserList;
