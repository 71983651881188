import { Datagrid, List, NumberField, TextField } from "react-admin";

export const OnboardingList = () => (
  <List>
    <Datagrid rowClick="edit">
      <NumberField source="index" />
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="text" />
    </Datagrid>
  </List>
);
