import {
  EditButton,
  ImageField,
  ListButton,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
import TitleField from "../fields/TitleField";

const ChannelShow = () => {
  return (
    <Show
      title={<TitleField label="User" source="email" />}
      actions={
        <TopToolbar>
          <ListButton />
          <EditButton />
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <Tab label="Channel">
          <TextField source="id" />
          <TextField label="Title" source="name" />
          <TextField source="subtitle" />
          <ImageField source="image.src" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ChannelShow;
