import { useMemo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { FieldProps, useRecordContext } from "react-admin";
import { nanoid } from "nanoid/non-secure";
import { BioText } from "../types/BioText";
import { ensureArray } from "../utils/array";
import { isLinkText } from "../types/LinkText";

const TextBlockField = ({ source }: FieldProps) => {
  const record = useRecordContext();
  const text: BioText = record[source!];

  const blocks = useMemo(() => {
    return ensureArray(text).map((item) => {
      if (typeof item === "string") {
        return {
          id: nanoid(),
          text: item,
        };
      }

      return {
        id: nanoid(),
        ...item,
      };
    });
  }, [text]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography component="div" whiteSpace="pre-wrap">
          {blocks.map((item) => {
            if (isLinkText(item)) {
              const { id, text, bold, color, url, uppercase } = item;
              return (
                <Link
                  key={id}
                  href={url}
                  variant="body2"
                  target="_blank"
                  rel="noreferrer"
                  color={color}
                  fontWeight={bold ? "bold" : undefined}
                  textTransform={
                    uppercase === true
                      ? "uppercase"
                      : uppercase === false
                      ? "none"
                      : undefined
                  }
                >
                  {text}
                </Link>
              );
            }

            const { id, text, bold, color, uppercase } = item;
            return (
              <Typography
                key={id}
                variant="body2"
                component="span"
                color={color}
                fontWeight={bold ? "bold" : undefined}
                textTransform={
                  uppercase === true
                    ? "uppercase"
                    : uppercase === false
                    ? "none"
                    : undefined
                }
              >
                {text}
              </Typography>
            );
          })}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TextBlockField;
