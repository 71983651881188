import { Datagrid, DateField, DeleteWithConfirmButton, List, TextField } from 'react-admin';

const UserList = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="title" />
        <DateField showTime source="createdAt" />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
