import Grid from "@mui/material/Grid";
import { useMemo, useState } from "react";
import {
  DateInput,
  Edit,
  email,
  InputProps,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
  useRecordContext,
} from "react-admin";
import TitleField from "../fields/TitleField";
import User from "../types/User";
import { flattenBioText, parseBioLinks } from "../utils/bio";

const UserEdit = () => {
  const transform = (data: any) => {
    const {
      bio,
      tags,
      name: { first, last },
    } = data;

    const full = [first, last]
      .filter((a) => a)
      .join(" ")
      .trim();

    return {
      ...data,
      bio,
      name: {
        first,
        last,
        full,
      },
      tags: tags.filter((a: any) => a),
    };
  };

  return (
    <Edit
      title={<TitleField label="User" source="email" />}
      transform={transform}
    >
      <SimpleForm>
        <TextInput disabled source="id" fullWidth validate={required()} />
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6} md={3}>
            <TextInput
              fullWidth
              isRequired
              label="First Name"
              source="name.first"
              validate={required()}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <TextInput
              fullWidth
              isRequired
              label="Last Name"
              source="name.last"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextInput
              fullWidth
              isRequired
              source="email"
              type="email"
              validate={[required(), email()]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <DateInput
              fullWidth
              isRequired
              source="birthDate"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SelectInput
              source="gender"
              isRequired
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
                { id: "Prefer not to say", name: "Prefer not to say" },
              ]}
              validate={required()}
            />
          </Grid>
        </Grid>
        <TagInput source="tags" />
        <TextInput
          fullWidth
          label="Bio"
          source="bio"
          multiline
          format={flattenBioText}
          parse={parseBioLinks}
        />

      </SimpleForm>
    </Edit>
  );
};

const TagInput = (props: InputProps) => {
  const [internalTags, updateInternalTags] = useState<ReadonlyArray<string>>(
    []
  );
  const user = useRecordContext<User>();
  const { data } = useGetOne(
    "Tags",
    { id: user?.id },
    { enabled: user !== undefined }
  );

  const tagSet = useMemo(() => {
    return new Set(data?.tags ?? []);
  }, [data]);

  const choices = useMemo(() => {
    const choices = new Set(tagSet);
    user?.tags.forEach((tag) => choices.add(tag));
    internalTags.forEach((tag) => choices.add(tag));

    return Array.from(choices).map((tag) => ({ id: tag, name: tag }));
  }, [user?.tags, tagSet, internalTags]);

  const onCreate = async () => {
    const tag = prompt("Enter new tag");
    if (!tag) {
      return 'empty';
    }

    updateInternalTags((tags) => [...tags, tag]);

    return { id: tag, name: tag };
  };

  return (
    <SelectArrayInput
      {...props}
      fullWidth
      choices={choices}
      onCreate={onCreate}
      createLabel="Create New Tag"
    />
  );
};

export default UserEdit;
