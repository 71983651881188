import { Divider, Stack } from '@mui/material';
import { CreateButton, DateField, DeleteWithConfirmButton, Labeled, ListButton, ReferenceField, Show, SimpleShowLayout, TextField, TopToolbar } from 'react-admin';

const UserShow = () => (
  <Show actions={
    <TopToolbar>
      <ListButton />
      <CreateButton />
      <DeleteWithConfirmButton />
    </TopToolbar>
  }>
    <SimpleShowLayout>
      <TextField source="id" />
      <Stack direction="row" spacing={2}>
        <Labeled>
          <DateField source="createdAt" />
        </Labeled>
        <Labeled>
          <TextField source="createdBy" />
        </Labeled>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Labeled>
          <DateField source="updatedAt" />
        </Labeled>
        <Labeled>
          <TextField source="updatedBy" />
        </Labeled>
      </Stack>
      <Divider />
      <TextField source="title" />
      <TextField source="message" />
      <ReferenceField source="userId" reference="User" link="show">
        <TextField source="name.full" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
