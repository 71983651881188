import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Title, TopToolbar, useGetOne } from "react-admin";

interface DashboardStats {
  id: string;
  totalUsers: number;
  totalConnections: number;
  totalTags: number;
  totalGroups: number;
}

const Dashboard = () => {
  const stats = useGetOne<DashboardStats>("Stats", { id: "Dashboard" });
  const { totalUsers, totalConnections /*, totalTags, totalGroups*/ } =
    stats.data ?? {};

  return (
    <>
      <Title title="Pulse Admin" />
      <TopToolbar />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="caption">Number of Users</Typography>
              <Typography variant="h3" fontWeight={200}>
                {totalUsers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="caption">Number of Connections</Typography>
              <Typography variant="h3" fontWeight={200}>
                {totalConnections}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/*
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="caption">Number of Tags</Typography>
              <Typography variant="h3" fontWeight={200}>
                {totalTags}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <Typography variant="caption">Number of Groups</Typography>
              <Typography variant="h3" fontWeight={200}>
                {totalGroups}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
  */}
      </Grid>
    </>
  );
};

export default Dashboard;
