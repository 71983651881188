import { Typography } from "@mui/material";
import { Clear as ClearIcon, Done as DoneIcon } from "@mui/icons-material";
import { useEffect } from "react";
import { useState } from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  EmailField,
  ImageField,
  List,
  ListButton,
  NumberField,
  ResourceContextProvider,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import OnboardingField from "../fields/OnboardingField";
import { ResetOnboardingField } from "../fields/ResetOnboardingField";
import SocialsField from "../fields/SocialsField";
import TagsField from "../fields/TagsField";
import TextBlockField from "../fields/TextBlockField";
import TitleField from "../fields/TitleField";

const UserShow = () => {
  return (
    <Show
      title={<TitleField label="User" source="email.primary.address" />}
      actions={
        <TopToolbar>
          <EditButton />
          <DeleteButton />
          <ListButton />
        </TopToolbar>
      }
    >
      <UserShowInner />
    </Show>
  );
};

const DeleteButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [isAdmin, setIsAdmin] = useState<boolean>();

  useEffect(() => {
    if (!record?.id) {
      return;
    }

    dataProvider
      .getOne("Admin", { id: record.id })
      .then(({ data }) => setIsAdmin(!!data))
      .catch(() => setIsAdmin(false));
  }, [dataProvider, record?.id]);

  if (!record) {
    return null;
  }

  return <DeleteWithConfirmButton disabled={isAdmin !== false} />;
};

const UserShowInner = () => {
  return (
    <TabbedShowLayout>
      <Tab label="Info">
        <TextField source="id" />
        <DateField source="dateAdded" />
        <DateField source="updatedAt" />
        <ImageField source="photoUrl" />
        <EmailField source="email.primary.address" />
        <TextField source="username" />
        <TextField label="First Name" source="name.first" />
        <TextField label="Last Name" source="name.last" />
        <TextField source="phone.primary.number" />
        <TextField source="gender" />
        <DateField source="birthDate" />
      </Tab>
      <Tab label="Profile">
        <TagsField source="tags" />
        <TextBlockField source="bio" />
        <SocialsField source="links" />
      </Tab>
      <Tab label="Location">
        <NumberField source="location.latitude" />
        <NumberField source="location.longitude" />
        <TextField source="address.line1" />
        <TextField source="address.line2" />
        <TextField source="address.city" />
        <TextField source="address.state" />
        <TextField source="address.country" />
        <TextField source="address.zip" />
      </Tab>
      <Tab label="Onboarding">
        <OnboardingField source="onboarding" />
        <ResetOnboardingField />
      </Tab>
      {/*<Tab label="Contacts">*/}
      {/*  <ContactsTab />*/}
      {/*</Tab>*/}
    </TabbedShowLayout>
  );
};

const ContactsTab = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <ResourceContextProvider value={`PulseContacts`}>
      <List disableSyncWithLocation empty={<EmptyContacts />}>
        <Datagrid
          bulkActionButtons={false}
          rowClick={(id, resource, record) => {
            if (record.onPulse) {
              return `/User/${id}/show`;
            }
            return "";
          }}
        >
          <TextField label="Full Name" source="name.full" />
          <TextField source="phone" />
          <TextField source="email" />
          <ImageField title="Image" label="Image" source="photoUrl" />
          <BooleanField source="onPulse" />
          <TagsField source="tags" />
        </Datagrid>
      </List>
    </ResourceContextProvider>
  );
};

const EmptyContacts = () => {
  return <Typography>This user has no contacts</Typography>;
};

export default UserShow;
