import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin';

const UserList = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput isRequired source="title" validate={required()} />
        <TextInput isRequired multiline source="message" validate={required()} />
        {/* <ReferenceInput label="User" source="userId" reference="User">
          <SelectInput />
        </ReferenceInput> */}
      </SimpleForm>
    </Create>
  );
};

export default UserList;
