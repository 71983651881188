import "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { AuthProvider, RaRecord, UserIdentity } from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  RAFirebaseOptions,
} from "react-admin-firebase";
import { IDataProvider } from "react-admin-firebase/dist/providers/DataProvider";
import { sleep } from "./utils/sleep";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const options: RAFirebaseOptions = {
  logging: process.env.NODE_ENV !== "production",
  metaFieldCasing: "camel",
  renameMetaFields: {
    created_at: "createdAt",
    created_by: "createdBy",
    updated_at: "updatedAt",
    updated_by: "updatedBy",
  },
  transformToDb: (_resourceName, documentData) => {
    let data = { ...documentData };

    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        data[key] = null;
      }
    });

    return data;
  },
};

const deleteUsers = async (uids: ReadonlyArray<string>) => {
  interface DeleteUserRequest {
    uids: ReadonlyArray<string>;
  }

  interface DeleteUserResponse {
    uids?: ReadonlyArray<string>;
    error?: string;
  }

  const functions = getFunctions();
  const callable = httpsCallable<DeleteUserRequest, DeleteUserResponse>(
    functions,
    "deleteUsers",
  );
  return callable({ uids });
};

const defaultDataProvider = FirebaseDataProvider(config, options);

export const dataProvider: IDataProvider = {
  ...defaultDataProvider,

  // NOTE: Override User deletion, calls firebase function to remove Auth User

  delete: async (resource, params) => {
    if (resource !== "User") {
      return defaultDataProvider.delete(resource, params);
    }

    const result = await deleteUsers([params.id as string]);
    if (result.data.error) {
      throw result.data.error;
    }

    // NOTE: try to wait for onAuthenticationUserDelete cloud function to be called
    await sleep(5000);

    return { data: params.previousData! };
  },
  deleteMany: async (resource, params) => {
    if (resource !== "User") {
      return defaultDataProvider.deleteMany(resource, params);
    }

    const result = await deleteUsers(params.ids as Array<string>);
    if (result.data.error) {
      throw result.data.error;
    }

    // NOTE: try to wait for onAuthenticationUserDelete cloud function to be called
    await sleep(5000);

    return {
      data: result.data.uids as Array<RaRecord["id"]>,
    };
  },
};

const defaultAuthProvider = FirebaseAuthProvider(config, options);
export const authProvider: AuthProvider = {
  ...defaultAuthProvider,

  login: async (params: any) => {
    const user = await defaultAuthProvider.login(params);
    const claims = await defaultAuthProvider.getPermissions({});

    if (!claims.admin) {
      await defaultAuthProvider.logout({});
      throw new Error("Login error: access denied");
    }

    return user;
  },

  getIdentity: async (): Promise<UserIdentity> => {
    const claims = await defaultAuthProvider.getPermissions({});
    const userId = claims.user_id;
    const profile = (
      await defaultDataProvider.app
        .firestore()
        .collection("User")
        .doc(userId)
        .get()
    ).data();

    return {
      id: userId,
      fullName: profile?.name.full,
      avatar: profile?.photoUrl,
    };
  },
};
