import {
  Chat as ChannelsIcon,
  DashboardCustomize as OnboardingIcon,
  Notifications as NotificationsIcon,
  SupervisorAccount as AdminIcon,
  Person as UsersIcon,
  Handshake as ConnectionsIcon,
} from "@mui/icons-material";
import { Admin, Resource } from "react-admin";
import AdminList from "./Admin/List";
import ChannelCreate from "./Channel/Create";
import ChannelEdit from "./Channel/Edit";
import { ChannelList } from "./Channel/List";
import ChannelShow from "./Channel/Show";
import { ConnectionList } from "./Connection";
import Dashboard from "./Dashboard";
import { authProvider, dataProvider } from "./firebase";
import Layout from "./Layout";
import {
  NotificationCreate,
  NotificationList,
  NotificationShow,
} from "./Notification";
import OnboardingCreate from "./Onboarding/Create";
import OnboardingEdit from "./Onboarding/Edit";
import { OnboardingList } from "./Onboarding/List";
import { UserEdit, UserList, UserShow } from "./User";

const App: React.FC = () => {
  return (
    <Admin
      dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      layout={Layout}
      requireAuth
      disableTelemetry
    >
      <Resource
        name="PulseProfiles"
        options={{
          label: "Users",
        }}
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        icon={UsersIcon}
      />
      {/*<Resource*/}
      {/*  name="Connection"*/}
      {/*  list={ConnectionList}*/}
      {/*  icon={ConnectionsIcon}*/}
      {/*/>*/}
      <Resource
        name="Notification"
        list={NotificationList}
        show={NotificationShow}
        create={NotificationCreate}
        icon={NotificationsIcon}
      />
      <Resource
        name="Onboarding"
        list={OnboardingList}
        create={OnboardingCreate}
        edit={OnboardingEdit}
        icon={OnboardingIcon}
      />
      <Resource
        name="Channel"
        list={ChannelList}
        create={ChannelCreate}
        show={ChannelShow}
        edit={ChannelEdit}
        icon={ChannelsIcon}
      />
      <Resource name="Admin" list={AdminList} icon={AdminIcon} />
    </Admin>
  );
};

export default App;
