export function ensureArray<T = any>(item: T | ReadonlyArray<T> | null | undefined): Array<T> {
  if (item === null || item === undefined) {
    return [];
  }

  if (Array.isArray(item)) {
    return [...item];
  }

  return [item] as Array<T>;
}

export function shiftUntil<T>(array: Array<T>, predicate: (_item: T) => boolean): T | undefined {
  while (array.length) {
    const item = array.shift();
    if (item === undefined || predicate(item)) {
      return item;
    }
  }
  return undefined;
}

export function sliceIntoChunks<T>(arr: ReadonlyArray<T>, chunkSize: number): Array<Array<T>> {
  const res: Array<Array<T>> = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

// NOTE (BO): Array.prototype.at() was failing on physical android device I was using
export function at<T>(arr: ReadonlyArray<T>, index: number): T | undefined {
  return arr[index < 0 ? arr.length + index : index];
}

export function removeAt<T>(arr: ReadonlyArray<T>, index: number): Array<T> {
  const newArray = [...arr];
  newArray.splice(index, 1);
  return newArray;
}

export function replaceAt<T>(arr: ReadonlyArray<T>, index: number, item: T): Array<T> {
  const newArray = [...arr];
  newArray.splice(index, 1, item);
  return newArray;
}
